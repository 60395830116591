<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5">
            <v-btn
                class="mr-3"
                depressed
                color="primary"
                icon
                rounded
                @click="closeDialog"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            {{ createForm ? 'NEW PROFORMA FEATURE' : 'EDIT PROFORMA FEATURE' }}
            <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
            <v-form ref="form" v-model="valid">
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="invoice.name"
                            prepend-icon="mdi-form-textbox"
                            label="Company Name *"
                            required
                            :rules="[rules.required]"
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-combobox
                            v-model="project"
                            :items="projects"
                            item-text="name"
                            hide-details
                            prepend-icon="mdi-account-hard-hat"
                            placeholder="Select a Project: "
                        >
                        </v-combobox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-text-field
                            v-model="invoice.address"
                            prepend-icon="mdi-map-marker"
                            label="Address *"
                            required
                            :rules="[rules.required]"
                        />
                    </v-col>
                    <v-col cols="6">
                        <vue-tel-input
                            v-model="invoice.phone"
                            mode="international"
                            :inputOptions="inputOptions"
                            validCharactersOnly
                            required
                            :rules="[rules.required]"
                            class="mt-3"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-text-field
                            type="number"
                            v-model="invoice.proformaValue"
                            prepend-icon="mdi-cash"
                            label="Vr Proforma *"
                            required
                            :rules="[rules.required]"
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-model="invoice.tariffItem"
                            prepend-icon="mdi-invoice-list-outline"
                            label="PA *"
                            required
                            :rules="[rules.required]"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea
                            v-model="invoice.description"
                            rows="2"
                            hide-details
                            label="Description"
                            prepend-icon="mdi-text"
                            required
                            :rules="[rules.required]"
                        >
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-form>
            <v-row>
                <v-col cols="12" class="pt-4 pb-0 mb-0">
                    <small v-if="createForm">* indicates required field</small>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                :loading="loading"
                @click="save"
                :disabled="!valid || !invoice.phone"
            >
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import { objDiff } from '@/helpers/objDiff.js'
import API from '@/services/api'

export default {
    name: 'ProformaFeaturesForm',
    props: {
        createForm: {
            type: Boolean,
            default: () => false,
        },
        project: {
            type: Object,
            default: () => ({}),
        },
        projects: {
            type: Array,
            default: () => [],
        },
        originalInvoice: {
            type: Object,
            default: () => ({}),
        },
        invoice: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        loading: false,
        valid: false,
        inputOptions: {
            placeholder: 'Phone *',
        },
        rules: {
            required: v => !!v || 'Invalid Value',
        },
    }),
    inject: {
        loadProformaFeature: {
            from: 'loadProformaFeature',
            default() {
                return null
            },
        },
        replaceProformaFeature: {
            from: 'replaceProformaFeature',
            default() {
                return null
            },
        },
    },
    computed: {
        invoiceDiff() {
            if (!this.createForm) {
                return objDiff(this.originalInvoice, this.invoice)
            } else {
                return null
            }
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async update() {
            try {
                this.loading = true
                if (this.project && this.project.id != this.invoice.projectId) {
                    this.invoiceDiff.projectId = this.project.id
                }
                const updatedInvoice = await API.updateProformaFeature(
                    this.invoice.id,
                    this.invoiceDiff
                )
                this.replaceProformaFeature(updatedInvoice)
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async create() {
            try {
                this.loading = true
                const newInvoice = await API.createProformaFeature({
                    ...(this.project && this.project.id
                        ? { projectId: this.project.id }
                        : {}),
                    ...this.invoice,
                })
                this.loadProformaFeature(newInvoice)
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async save() {
            if (this.createForm) {
                await this.create()
            } else {
                await this.update()
            }
        },
        closeDialog() {
            this.$emit('close')
        },
    },
}
</script>

<style lang="scss" scoped>
.vue-tel-input {
    border: none;
    border-radius: 0px;
    font-size: 16px;
}
.vue-tel-input.disabled {
    opacity: 0.5;
}

.vue-tel-input:focus-within {
    box-shadow: none;
    border-color: 'primary';
    color: 'primary';
}
</style>
